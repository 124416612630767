import _ from 'lodash'; // usefull library
import sass from './assets/sass/styles.sass'; // used in webpack.config.js
import { tns } from './jscomponents/tiny-slider';

const mySetup = {
  myImagePath: "img/", // dist image folder
}

const slider = tns({
  container: '.carousel__list',
  items: 2,
  slideBy: 1,
  autoplay: true,
  mode: 'carousel',
  gutter: 24,
  controls: false,
  navContainer: false,
  autoplayTimeout: 3000,
  nav: false,
  autoplayButton: false,
  autoplayButtonOutput: false,
  responsive: {
    680: {
      items: 3
    },
    880: {
      items: 2
    },
    1024: {
      items: 3
    }
  }
});